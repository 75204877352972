<template>
  <div>
    <tab-headers>
      Hello, {{ user.firstName }}!
      <template #text> Welcome to your dashboard </template>
    </tab-headers>
    <div class="md:flex">
      <div class="md:w-8/12 bg-white shadow-md rounded p-5 md:mr-5">
        <div class="bg-primary-dark flex justify-between px-3 py-2 rounded">
          <div
            class="
              flex flex-col
              text-white
              items-start
              justify-center
              md:mr-3
              text-sm
            "
          >
            Update Your Profile
            <br />
            <a-button
              class="mt-3"
              type="primary"
              @click="$router.push('profile')"
              >Update Profile</a-button
            >
          </div>
          <div>
            <img class="w-28 h-28" src="../../assets/svg/user.svg" />
          </div>
        </div>
        <div class="grid md:grid-cols-3 grid-cols-1 md:gap-4 gap-y-4 mt-5">
          <div class="bg-white shadow-md rounded-md text-center py-4 px-2">
            <img
              src="../../assets/svg/user_plus.svg"
              class="w-24 h-24 mx-auto"
              alt=""
            />
            <h4 class="font-semibold text-xl my-3 mb-1">Subscription</h4>
            <p class="text-xs text-gray-500 mb-5">
              Activate your monthly Subscription to be listed on the lawyers
              Directory here
            </p>
            <a-button
              type="primary"
              @click="$router.push('subscription?tab=subscription')"
              >Go Here</a-button
            >
          </div>
          <div class="bg-white shadow-md rounded-md text-center py-4 px-2">
            <img
              src="../../assets/svg/user_time.svg"
              class="w-24 h-24 mx-auto"
              alt=""
            />
            <h4 class="font-semibold text-xl my-3 mb-1">Listing</h4>
            <p class="text-xs text-gray-500 mb-5">
              Subscribe to listing plans to boost your visibility locally or
              globally
            </p>
            <a-button
              type="primary"
              @click="$router.push('subscription?tab=listing')"
              >Go Here</a-button
            >
          </div>
          <div class="bg-white shadow-md rounded-md text-center py-4 px-2">
            <img
              src="../../assets/svg/template_green.svg"
              class="w-20 h-20 mx-auto"
              alt=""
            />
            <h4 class="font-semibold text-xl my-3 mb-1">Agreement Template</h4>
            <p class="text-xs text-gray-500 mb-5">
              Buy and edit Agreement template.
            </p>
            <a-button
              type="primary"
              @click="$router.push('/lawyer/draft-an-agreement')"
              >Go Here</a-button
            >
          </div>
        </div>
      </div>
      <div class="md:w-4/12">
        <div class="bg-green-100 rounded-md p-3 shadow-md mb-3">
          <h3 class="font-semibold">Your Active Trial Subscription</h3>
          <template v-if="TrialPlans.length === 0">
            <p>You don't have any trial plan active</p>
          </template>
          <template v-else>
            <p v-for="plan in TrialPlans" :key="plan._id">
              You have a trial subscription plan, due to expire on
              <span class="font-semibold text-primary-dark">
                {{ new Date(plan.endDate).toDateString() }}
              </span>
            </p>
          </template>
        </div>
        <div class="bg-blue-100 rounded-md p-3 shadow-md mb-3">
          <h3 class="font-semibold">Your Active Subscription</h3>
          <template v-if="subscriptionPlans.length === 0">
            <p>You don't have any subscription plan active</p>
          </template>
          <template v-else>
            <p v-for="plan in subscriptionPlans" :key="plan._id">
              You have a active subscription plan due to expire on
              <span class="font-semibold text-blue-500">
                {{ new Date(plan.endDate).toDateString() }}
              </span>
            </p>
          </template>
        </div>
        <div class="bg-amber-100 rounded-md p-3 shadow-md">
          <h3 class="font-semibold">Your Active Listing Plan</h3>
          <template v-if="listingPlans.length === 0">
            <p>You are not subscribed to any listing plan</p>
          </template>
          <template v-else>
            <p v-for="plan in listingPlans" :key="plan._id">
              You have a active
              <span class="text-amber-500 font-semibold"
                >{{ plan.plan.listingCategory }} {{ plan.type }} plan</span
              >
              ,which started on
              <span class="text-amber-500 font-semibold">
                {{ new Date(plan.startDate).toDateString() }}
              </span>
              spanning
              <span class="text-amber-500 font-semibold"
                >{{ plan.plan.duration }}
                {{ plan.plan.durationPeriod }}
              </span>
              and will expire on
              <span class="text-amber-500 font-semibold">
                {{ new Date(plan.endDate).toDateString() }}
              </span>
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TabHeaders from '../../components/typography/TabHeaders.vue'
import lawyerSubscriptionApi from '../../api/lawyers/subscription'

const isEmpty = str => str === '' || str === undefined

export default {
  components: { TabHeaders },
  layout: 'lawyerDashboard',
  data() {
    return {
      userPlans: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    isProfileUpdated() {
      if (
        isEmpty(this.user.firstName) ||
        isEmpty(this.user.lastName) ||
        isEmpty(this.user.phone) ||
        isEmpty(this.user.avatar) ||
        isEmpty(this.user.businessAddress) ||
        isEmpty(this.user.callToBarYear) ||
        isEmpty(this.user.email) ||
        isEmpty(this.user.gender) ||
        isEmpty(this.user.lawFirm) ||
        isEmpty(this.user.otherNames) ||
        isEmpty(this.user.proofOfPayment) ||
        isEmpty(this.user.state) ||
        isEmpty(this.user.town) ||
        this.user.practice.length === 0
      ) {
        return false
      } else {
        return true
      }
    },
    subscriptionPlans() {
      return this.userPlans.filter(
        plan =>
          plan.status === 'active' &&
          plan.type === 'subscription' &&
          !plan.isTrial
      )
    },
    listingPlans() {
      return this.userPlans.filter(
        plan =>
          plan.status === 'active' && plan.type === 'listing' && !plan.isTrial
      )
    },
    TrialPlans() {
      return this.userPlans.filter(
        plan => plan.status === 'active' && plan.isTrial
      )
    }
  },
  created() {
    this.getUserPlan()
  },
  methods: {
    async getUserPlan() {
      const req = await lawyerSubscriptionApi(this.axios).getCurrentUserPlan({
        params: {
          populate: 'plan'
        }
      })
      this.userPlans = req.data.data
      // this.currentPlan = req.data
    }
  }
}
</script>
